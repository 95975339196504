export enum QueryFields {
  Groups = "groups",
  Products = "products",
  Images = "images",
  Rates = "rates",
  Auth = "auth",
  Users = "users",
  Promocodes = "promocodes",
  Orders = "orders",
  ProductStatuses = "productStatuses",
}

export enum OrderStatus {
  Formed = 1,
  PaymentWaiting,
  Processing,
  Ready,
  OnDelivery,
  Delivered,
  PaymentError,
  OrderCanceled,
}

export const orderStatusText = new Map(
  Object.entries({
    Formed: "Сформирован",
    PaymentWaiting: "Ожидается оплата",
    Processing: "Букет собирается",
    Ready: "Букет собран",
    OnDelivery: "У курьера",
    Delivered: "Вручен",
    PaymentError: "Ошибка оплаты",
    OrderCanceled: "Отмена заказа",
  }),
);

export const selectedTimeText = [
  "8:00 - 11:00",
  "11:00 - 14:00",
  "14:00 - 17:00",
  "17:00 - 20:00",
  "20:00 - 22:00",
];

export const orderDeliveryCity = [null, "Караганда", "Алматы", "Астана"];
export const orderDeliveryTypeText = [null, "Доставка", "Самовывоз"];
export const orderPaymentTypeText = [
  null,
  "Наличный",
  "Безналичный",
  "Kaspi",
  "PayPal",
  "Золотая корона",
  "Western Union",
  "PaySend",
];
export const orderPaymentStatus = [
  "Отсутствует",
  "Ожидается оплата",
  "Оплачен",
  "Ошибка оплаты",
  "Отменен",
];

export interface ModelGroup {
  id: number;
  description: string;
}

export interface ModelRate {
  id: number;
  name: string;
  rate: number;
}

export interface ModelProduct {
  id: number;
  group_id: number;
  bonus: number;
  product_description: string;
  product_name: string;
  group_name: string;
  product_price: ModelPrice[];
  images: ModelImage[];
  status: number;
}

export interface ModelProductStatuses {
  id: number;
  description: string;
}

export interface ModelImage {
  id: number | string;
  body: string;
  uid?: string;
}

export interface ImageDelete {
  id: number;
  productId: number;
  groupId: number;
}

export interface ModelPrice {
  Currency: string;
  Value: number;
}

export interface ModelAuth {
  token: string;
}

export interface ModelUser {
  id: number;
  first_name: string;
  second_name: string;
  login: string;
  email: string;
  phone: string;
  bonusCount: number;
}

export interface ModelPromocode {
  id: number;
  name: string;
  discount: number;
  count: number;
  create_date: string;
  expiration_date: string;
  infinite: boolean;
  amount: number;
  active: boolean;
}

export interface ModelOrder {
  create_date: string;
  delivery_cost: number;
  district: string;
  customer_f_name: string;
  customer_phone: string;
  customer_s_name: string;
  decipient_address: string;
  delivery_comment: string;
  delivery_time: string;
  delivery_type: number;
  id: number;
  order_id: string;
  order_status: number;
  payment_type: number;
  product_count: number;
  product_id: number;
  recipient_f_name: string;
  recipient_phone: string;
  Basket: BasketProduct[];
  bonus_accrued: number;
  bonus_spent: number;
  Amount: number;
  payment_status: number;
  payment_id: string;
  usepromo: string;
  anonymous: boolean;
  selectedTime: string;
}

export interface BasketProduct {
  group_id: number;
  product_count: number;
  product_id: number;
}
