import {
  Descriptions,
  Badge,
  List,
  Row,
  Typography,
  Space,
  Button,
  Tag,
} from "antd";
import { useParams } from "react-router-dom";
import { useGetOrderById, usePaymentResult } from "../../../hooks/useOrders";
import { ErrorPage } from "../ErrorPage/ErrorPage";
import {
  ModelOrder,
  orderDeliveryTypeText,
  orderPaymentStatus,
  orderPaymentTypeText,
  OrderStatus,
  orderStatusText,
} from "../../../types/Models";
import { formatDate, formatNumber } from "../../../utils/utils";
import { OrderBasketProduct } from "./OrderBasketProduct";
import { Loader } from "../../shared/Loader/Loader";
import { useLocalizedDate } from "../../../hooks/useLocalizedDate";

const { Title } = Typography;

export const OrderPage = () => {
  const { id } = useParams();
  const mutationPaymentResult = usePaymentResult();

  const { data: order, isLoading } = useGetOrderById(id);
  const localizedDate = useLocalizedDate(order && order.create_date);

  if (isLoading) {
    return <Loader />;
  }

  if (!order) {
    return <ErrorPage code={404} />;
  }

  const markPaid = (item: ModelOrder) => {
    mutationPaymentResult.mutate({
      order_id: item.order_id,
      payment_id: item.payment_id,
      status: "2",
    });
  };

  const cancelOrder = (item: ModelOrder) => {
    mutationPaymentResult.mutate({
      order_id: item.order_id,
      payment_id: item.payment_id,
      status: "4",
    });
  };

  return (
    <div>
      <Row justify="space-between" align="middle" style={{ marginBottom: 24 }}>
        <Title level={4} style={{ margin: 0 }}>
          {"Заказ #" + id}
        </Title>

        <Space>
          {order.payment_status === 1 && (
            <Button type="primary" onClick={() => markPaid(order)}>
              Отметить оплаченным
            </Button>
          )}

          {order.payment_status !== 4 && order.payment_status !== 0 && (
            <Button type="ghost" danger onClick={() => cancelOrder(order)}>
              Отменить
            </Button>
          )}
        </Space>
      </Row>
      <Descriptions bordered column={4} style={{ marginBottom: 24 }}>
        <Descriptions.Item label="Заказчик" span={2}>
          {order.customer_f_name} {order.customer_s_name}
        </Descriptions.Item>
        <Descriptions.Item label="Телефон" span={2}>
          {order.customer_phone}
        </Descriptions.Item>
        <Descriptions.Item label="Получатель" span={2}>
          {order.recipient_f_name}
        </Descriptions.Item>
        <Descriptions.Item label="Телефон" span={2}>
          {order.recipient_phone}
        </Descriptions.Item>

        <Descriptions.Item label="Тип доставки" span={2}>
          {orderDeliveryTypeText[order.delivery_type]}
        </Descriptions.Item>
        <Descriptions.Item label="Стоимость доставки" span={2}>
          {order.delivery_cost}
        </Descriptions.Item>
        <Descriptions.Item label="Дата заказа" span={2}>
          {localizedDate && formatDate(localizedDate)}
        </Descriptions.Item>
        <Descriptions.Item label="Дата доставки" span={2}>
          {formatDate(order.delivery_time, true) + " | " + order.selectedTime}
        </Descriptions.Item>
        <Descriptions.Item label="Статус заказа" span={2}>
          {orderStatusText.get(OrderStatus[order.order_status])}
        </Descriptions.Item>
        <Descriptions.Item label="Статус оплаты" span={2}>
          <Badge
            status={
              order.payment_status === 2
                ? "success"
                : order.payment_status === 4
                ? "error"
                : "processing"
            }
            text={orderPaymentStatus[order.payment_status]}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Тип оплаты" span={2}>
          {orderPaymentTypeText[order.payment_type]}
        </Descriptions.Item>

        <Descriptions.Item label="Регион" span={2}>
          {order.district}
        </Descriptions.Item>
        <Descriptions.Item label="Адрес" span={2}>
          {order.decipient_address}
        </Descriptions.Item>

        <Descriptions.Item label="Начислено бонусов" span={2}>
          {order.bonus_accrued}
        </Descriptions.Item>
        <Descriptions.Item label="Потраченно бонусов" span={2}>
          {order.bonus_spent}
        </Descriptions.Item>
        <Descriptions.Item label="Анонимный заказ" span={2}>
          {order.anonymous ? <Tag color="#f50">Да</Tag> : "Нет"}
        </Descriptions.Item>
        <Descriptions.Item label="Комментарий к доставке">
          {order.delivery_comment}
        </Descriptions.Item>
      </Descriptions>
      <List
        size="large"
        header={<b>Корзина:</b>}
        footer={
          <Row justify="space-between">
            <div>
              <b>Общая стоимость: {formatNumber(order.Amount)} KZT</b>
            </div>
            {order.usepromo !== "none" && order.usepromo !== "" && (
              <div>
                Promocode: <b>{order.usepromo}</b>
              </div>
            )}
          </Row>
        }
        bordered
        dataSource={order.Basket}
        renderItem={(item) => <OrderBasketProduct {...item} />}
      />
    </div>
  );
};
