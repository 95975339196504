import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "../../../hoc/AuthProvider";
import { RequireAuth } from "../../../hoc/RequireAuth";
import { MainLayout } from "../../Layout/MainLayout";
import { ErrorPage } from "../ErrorPage/ErrorPage";
import { Groups } from "../Groups/Groups";
// import { Home } from "../Home/Home";
import { Login } from "../Login/Login";
import { OrderPage } from "../Orders/OrderPage";
import { Orders } from "../Orders/Orders";
import { Products } from "../Products/Products";
import { CreatePromocode } from "../Promocodes/CreatePromocode";
import { Promocodes } from "../Promocodes/Promocodes";
import { Rates } from "../Rates/Rates";
import { Users } from "../Users/Users";
import "./App.scss";

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<RequireAuth element={<Products />} />} />
          <Route
            path="/groups"
            element={<RequireAuth element={<Groups />} />}
          />
          <Route
            path="/products"
            element={<RequireAuth element={<Products />} />}
          />
          <Route path="/rates" element={<RequireAuth element={<Rates />} />} />
          <Route path="/users" element={<RequireAuth element={<Users />} />} />
          <Route
            path="/promocodes/create"
            element={<RequireAuth element={<CreatePromocode />} />}
          />
          <Route
            path="/promocodes"
            element={<RequireAuth element={<Promocodes />} />}
          />
          <Route
            path="/orders"
            element={<RequireAuth element={<Orders />} />}
          />
          <Route
            path="/orders/:id"
            element={<RequireAuth element={<OrderPage />} />}
          />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<ErrorPage code={404} />} />
      </Routes>
    </AuthProvider>
  );
};

export default App;
