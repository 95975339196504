import { BasketProduct } from "../../../types/Models";
import { ErrorPage } from "../ErrorPage/ErrorPage";
import { useGetProductById } from "../../../hooks/useProduct";
import { List } from "antd";
import { formatNumber } from "../../../utils/utils";
import { Loader } from "../../shared/Loader/Loader";

export const OrderBasketProduct = (item: BasketProduct) => {
  const { data: product, isLoading } = useGetProductById(
    item.product_id,
    item.group_id,
  );

  if (!product) {
    return <ErrorPage code={404} />;
  }

  if (isLoading) {
    return <Loader />;
  }
  return (
    <List.Item
      extra={<img width={100} alt="logo" src={product.images[0].body} />}
    >
      <div style={{ flexBasis: "20%" }}>{product.group_name}</div>
      <div style={{ flexBasis: "20%" }}>{product.product_name}</div>
      <div style={{ flexBasis: "20%" }}>
        {formatNumber(product.product_price[0].Value) + " KZT"}
      </div>
      <div>{item.product_count} шт</div>
    </List.Item>
  );
};
