import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Divider,
  message,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Switch,
  Typography,
} from "antd";
import { Fragment, useState } from "react";
import {
  useCreateImage,
  useCreateProduct,
  useDeleteProduct,
  useEditProduct,
  useGetProducts,
} from "../../../hooks/useProduct";
import { ModelImage, ModelProduct } from "../../../types/Models";
import { ProductForm, ProductFormData } from "./ProductForm";
import { formatNumber } from "../../../utils/utils";
import "./Products.scss";
import { Loader } from "../../shared/Loader/Loader";
import { useGetGroups } from "../../../hooks/useGroup";
import ButtonGroup from "antd/lib/button/button-group";

const { Title } = Typography;
const { Meta } = Card;

export const Products = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editableProduct, setEditableProduct] = useState<false | ModelProduct>(
    false,
  );
  const [activeFilterButton, setActiveFilterButton] = useState<number>(0);
  const [activeStatus, setActiveStatus] = useState<number>(0);
  const { data, isLoading } = useGetProducts();
  const { data: groups } = useGetGroups();

  const mutationAdd = useCreateProduct();
  const mutationImageAdd = useCreateImage();
  const mutationDelete = useDeleteProduct();
  const mutationEdit = useEditProduct();

  const { isLoading: isAdding } = mutationAdd;
  const { isLoading: isEditing } = mutationEdit;
  const { isLoading: isAddingImage } = mutationImageAdd;

  const handleFilterChange = (id: number) => {
    setActiveFilterButton(id);
  };

  const handleStatusChange = (id: number) => {
    setActiveStatus(id);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditableProduct(false);
  };

  const handleDelete = async (id: number) => {
    try {
      await mutationDelete.mutateAsync(id);
      message.success("Товар успешно удален");
    } catch (error) {
      message.error("Ошибка удаления товара");
    }
  };

  const handleEdit = (item: ModelProduct) => {
    setEditableProduct(item);
    showModal();
  };

  const onFinish = async (data: ProductFormData, images: ModelImage[]) => {
    const { name, desc, price, groupId, statusId, bonus } = data;

    const newData: Partial<ModelProduct> = {
      id: editableProduct ? editableProduct.id : 0,
      group_id: groupId,
      bonus: Number(bonus) || 0,
      product_name: name,
      product_description: desc,
      images: [],
      product_price: [
        {
          Currency: "kzt",
          Value: +price,
        },
      ],
      status: statusId,
    };

    if (editableProduct) {
      await mutationEdit.mutateAsync(newData);
      mutationImageAdd.mutate({
        groupId: editableProduct.group_id,
        productId: editableProduct.id,
        images: images
          .filter(({ body }) => !body.startsWith("https"))
          .map(({ body }) => ({ body })),
      });
    } else {
      try {
        const response = await mutationAdd.mutateAsync(newData);
        mutationImageAdd.mutate({
          groupId: response.data.group_id,
          productId: response.data.id,
          images: images.map(({ body }) => ({ body })),
        });

        message.success("Товар успешно добавлен");
      } catch (error) {
        message.error("Ошибка добавления товара");
      }
    }
  };

  const [spinStatus, setSpinStatus] = useState<number | null>(null);
  const handleStatusSwitchChange = async (
    checked: boolean,
    product: ModelProduct,
  ) => {
    const newProduct = { ...product, status: checked ? 1 : 2 };
    setSpinStatus(product.id);
    await mutationEdit
      .mutateAsync(newProduct)
      .then(() => message.success("Статус успешно обновлен"))
      .catch(() => message.error("Ошибка обновления статуса товара"))
      .finally(() => setSpinStatus(null));
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <Row justify="space-between">
        <Title level={2}>Продукты</Title>
        <Button type="primary" onClick={showModal}>
          Добавить
        </Button>
      </Row>
      <Row gutter={[8, 8]}>
        <ButtonGroup>
          <Button
            style={{ marginRight: 8 }}
            type={activeStatus === 0 ? "primary" : "default"}
            onClick={() => handleStatusChange(0)}
          >
            Показать все
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button
            style={{ marginRight: 8 }}
            type={activeStatus === 1 ? "primary" : "default"}
            onClick={() => handleStatusChange(1)}
          >
            Только активные
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button
            type={activeStatus === 2 ? "primary" : "default"}
            onClick={() => handleStatusChange(2)}
          >
            Только скрытые
          </Button>
        </ButtonGroup>
        <Divider style={{ margin: "8px 0", background: "#ccc" }} />
      </Row>
      {groups && (
        <div style={{ margin: "12px 0" }}>
          <Row gutter={[8, 8]}>
            <ButtonGroup style={{ marginRight: 8 }}>
              <Button
                type={activeFilterButton === 0 ? "primary" : "default"}
                onClick={() => handleFilterChange(0)}
              >
                Показать все
              </Button>
            </ButtonGroup>
            {groups.map((group) => (
              <ButtonGroup key={group.id} style={{ marginRight: 8 }}>
                <Button
                  type={activeFilterButton === group.id ? "primary" : "default"}
                  onClick={() => handleFilterChange(group.id)}
                >
                  {group.description}
                </Button>
              </ButtonGroup>
            ))}
          </Row>
        </div>
      )}
      <Row gutter={[16, 16]}>
        {data &&
          [...data].reverse().map((product) => (
            <Fragment key={product.id}>
              {(product.group_id === activeFilterButton ||
                activeFilterButton === 0) &&
              (activeStatus === 0 ? true : product.status === activeStatus) ? (
                <Col xs={8} xl={6}>
                  <Card
                    className="product-card"
                    cover={
                      <img
                        alt="example"
                        src={
                          product.images ? product.images[0].body : undefined
                        }
                      />
                    }
                    actions={[
                      <Button
                        shape="circle"
                        onClick={() => handleEdit(product)}
                        icon={<EditOutlined />}
                      />,
                      <Switch
                        defaultChecked={product.status === 1}
                        onChange={(checked) =>
                          handleStatusSwitchChange(checked, product)
                        }
                      />,
                      <Popconfirm
                        title="Вы уверены, что хотите удалить этот товар?"
                        onConfirm={() => handleDelete(product.id)}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button
                          shape="circle"
                          danger
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>,
                    ]}
                  >
                    <Meta
                      title={
                        <div>
                          {product.product_name}
                          <br />
                          <Badge
                            status={product.status === 1 ? "success" : "error"}
                            text={product.status === 1 ? "Активен" : "Скрыт"}
                          />
                          {spinStatus === product.id && <Spin />}
                        </div>
                      }
                      description={
                        formatNumber(product.product_price[0].Value) + " KZT"
                      }
                    />
                  </Card>
                </Col>
              ) : null}
            </Fragment>
          ))}
      </Row>
      <Modal
        title={editableProduct ? "Редактировать товар" : "Создать товар"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        {((isAdding && !editableProduct) ||
          (isEditing && editableProduct) ||
          isAddingImage) && (
          <div>
            <Title level={3}>Статус товара:</Title>
            {!editableProduct && (
              <Alert
                message={`Добавление нового товара: ${
                  isAdding ? "В процессе..." : "Готово"
                }`}
                type={isAdding ? "warning" : "success"}
                style={{ marginBottom: 12 }}
              />
            )}
            {editableProduct && (
              <Alert
                message={`Редактирование товара: ${
                  isEditing ? "В процессе..." : "Готово"
                }`}
                type={isEditing ? "warning" : "success"}
                style={{ marginBottom: 12 }}
              />
            )}
            <Alert
              message={`
              Загрузка фотографий товара:
              ${isAddingImage ? "В процессе..." : "Готово"}`}
              type={isAddingImage ? "warning" : "success"}
            />
            <Loader />
          </div>
        )}
        {!(isAdding || (isEditing && editableProduct) || isAddingImage) && (
          <ProductForm onFinish={onFinish} product={editableProduct} />
        )}
      </Modal>
    </>
  );
};
