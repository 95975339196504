import { apiRoutes } from "../routes";
import { api } from "../services/api";
import { ModelUser, QueryFields } from "../types/Models";
import { useFetch, useGenericMutation } from "../utils/reactQuery";

export const useGetUsers = () => {
  const context = useFetch<ModelUser[]>(
    QueryFields.Users,
    apiRoutes.getUsers,
    undefined,
    {
      retry: false,
    },
  );
  return { ...context, data: context.data };
};

export const useUpdateUser = () => {
  return useGenericMutation<ModelUser, ModelUser>(
    (data: ModelUser) =>
      api.patch(`${apiRoutes.updateUser}?userid=${data.id}`, data),
    QueryFields.Users,
  );
};
