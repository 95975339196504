import { apiRoutes } from "../routes";
import { api } from "../services/api";
import {
  ImageDelete,
  ModelImage,
  ModelProduct,
  ModelProductStatuses,
  QueryFields,
} from "../types/Models";

import {
  useDelete,
  useFetch,
  useGenericMutation,
  usePost,
  useUpdate,
} from "../utils/reactQuery";

export const useGetProducts = () => {
  const context = useFetch<ModelProduct[]>(
    QueryFields.Products,
    apiRoutes.getProducts,
    undefined,
    {
      retry: false,
    },
  );
  return { ...context, data: context.data };
};

export const useGetProductStatuses = () => {
  const context = useFetch<ModelProductStatuses[]>(
    QueryFields.ProductStatuses,
    apiRoutes.getProductStatuses,
    undefined,
    { retry: false },
  );
  return { ...context, data: context.data };
};

export const useGetProductById = (id: number, group_id: number) => {
  const context = useFetch<ModelProduct>(
    QueryFields.Products,
    apiRoutes.getProductById,
    {
      id,
      group_id,
    },
    {
      retry: false,
    },
  );
  return { ...context, data: context.data };
};

export const useCreateProduct = () => {
  return usePost<Partial<ModelProduct>, ModelProduct>(
    QueryFields.Products,
    apiRoutes.createProduct,
  );
};

export const useDeleteProduct = () => {
  return useDelete(QueryFields.Products, apiRoutes.deleteProduct);
};

export const useCreateImage = () => {
  return usePost(QueryFields.Products, apiRoutes.createImage);
};

export const useGetProductImages = () => {
  const context = useFetch<ModelImage[]>(
    QueryFields.Images,
    apiRoutes.getProducts,
    undefined,
    {
      retry: false,
    },
  );
  return { ...context, data: context.data };
};

export const useEditProduct = () => {
  return useUpdate(QueryFields.Products, apiRoutes.editProduct);
};

export const useDeleteImage = () => {
  return useGenericMutation<ImageDelete, ImageDelete>(
    (data: ImageDelete) =>
      api.delete(
        `${apiRoutes.deleteImage}/${data.groupId}/${data.productId}/${data.id}`,
      ),
    QueryFields.Products,
  );
};
