import { createContext, FC, useState } from "react";
import { ModelAuth } from "../types/Models";

interface IAuthContext {
  authInfo: ModelAuth | null;
  signin: (auth: ModelAuth, cb?: () => void) => void;
  signout: (cb?: () => void) => void;
}

export const AuthContext = createContext<IAuthContext | null>(null);

interface Props {
  children: any;
}

export const AuthProvider: FC<Props> = ({ children }) => {
  const token = sessionStorage.getItem("token");
  const [authInfo, setAuthInfo] = useState<ModelAuth | null>(
    token ? { token } : null,
  );

  const value: IAuthContext = {
    authInfo,
    signin: (auth: ModelAuth, cb) => {
      setAuthInfo(auth);
      sessionStorage.setItem("token", auth.token);
      if (cb) {
        cb();
      }
    },
    signout: (cb) => {
      setAuthInfo(null);
      sessionStorage.removeItem("token");
      if (cb) {
        cb();
      }
    },
  };

  return (
    <AuthContext.Provider value={value}>
      <>{children}</>
    </AuthContext.Provider>
  );
};
