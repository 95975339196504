import { Button, Result } from "antd";
import { Link } from "react-router-dom";

interface ErrorPageProps {
  code: 404 | 500;
}

export const ErrorPage = (props: ErrorPageProps) => {
  if (props.code === 500) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={
          <Link to="/">
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    );
  }

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Link to="/">
          <Button type="primary">Back Home</Button>
        </Link>
      }
    />
  );
};
