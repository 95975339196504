import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

interface RequireAuthProps {
  element: JSX.Element;
}

export const RequireAuth = (props: RequireAuthProps) => {
  const location = useLocation();
  const auth = useAuth();

  if (!auth?.authInfo) {
    return <Navigate to="/login" state={{ form: location }} />;
  }

  return props.element;
};
