import { apiRoutes } from "../routes";
import { ModelGroup, QueryFields } from "../types/Models";
import { useDelete, useFetch, usePost, usePutGroup } from "../utils/reactQuery";

export const useGetGroups = () => {
  const context = useFetch<ModelGroup[]>(
    QueryFields.Groups,
    apiRoutes.getGroups,
    undefined,
    {
      retry: false,
    },
  );
  return { ...context, data: context.data };
};

export const useCreateGroup = () => {
  return usePost(QueryFields.Groups, apiRoutes.createGroup);
};

export const useDeleteGroup = () => {
  return useDelete(QueryFields.Groups, apiRoutes.deleteGroup);
};

export const useEditGroup = () => {
  return usePutGroup(QueryFields.Groups, apiRoutes.editGroup);
};
