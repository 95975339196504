import axios from "axios";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../hoc/AuthProvider";

export function useAuth() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.error(error);
      if (error.response?.status === 401) {
        auth?.signout(() => navigate("/login"));
      }
      return error;
    },
  );
  return auth;
}
