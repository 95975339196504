export const baseUrl = "https://prod.cvetozavr.kz:5009/api/v1";
const privateUrl = baseUrl + "/restricted";
const imageUrl = "https://prod.cvetozavr.kz:5015/api/v1";

export const apiRoutes = {
  login: baseUrl + "/login",

  getGroups: privateUrl + "/group/list",
  createGroup: privateUrl + "/group/add",
  editGroup: privateUrl + "/group/edit",
  deleteGroup: privateUrl + "/group/del",

  getProducts: privateUrl + "/product/list",
  getProductById: privateUrl + "/product/item",
  createProduct: privateUrl + "/product/add",
  editProduct: privateUrl + "/product/edit",
  deleteProduct: privateUrl + "/product/del",
  getProductStatuses: privateUrl + "/product/status",

  getRates: privateUrl + "/rate/list",
  updateRates: privateUrl + "/rate/edit",

  getUsers: privateUrl + "/user/list",
  updateUser: privateUrl + "/user/edit",

  getOrders: privateUrl + "/order/list",
  getOrdersByCity: privateUrl + "/order/city",

  editOrder: privateUrl + "/order/edit",
  getOrderById: privateUrl + "/order/single",

  markOrderPaid: privateUrl + "/payment/result",

  getPromocodes: privateUrl + "/promocode/list",
  createPromocode: privateUrl + "/promocode/add",
  deletePromocode: privateUrl + "/promocode/del",

  createImage: imageUrl + "/image/add",
  deleteImage: imageUrl + "/image/del",
};
