import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import { useCreatePromocode } from "../../../hooks/usePromocodes";

const { Title } = Typography;

interface CreatePromocodeFromData {
  name: string;
  discount: number;
  startDate: moment.Moment;
  expDate: moment.Moment;
}

export const CreatePromocode = () => {
  const [form] = Form.useForm();
  const createMutation = useCreatePromocode();

  const onFinish = async (formData: CreatePromocodeFromData) => {
    try {
      await createMutation.mutateAsync({
        create_date: formData.startDate.format("YYYY-MM-DD HH:mm:ss"),
        expiration_date: formData.expDate.format("YYYY-MM-DD HH:mm:ss"),
        ...formData,
      });
    } catch (e) {
      message.error("Не удалось создать промокод");
      throw e;
    }

    message.success("Промокод успешно добавлен");
    form.resetFields();
  };

  return (
    <div>
      <Row justify="space-between">
        <Title level={2}>Создание промокода</Title>
      </Row>
      <Form
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 6 }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          labelAlign="left"
          label="Название"
          name="name"
          rules={[{ required: true, message: "Обязательное поле!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          label="Скидка"
          name="discount"
          rules={[{ required: true, message: "Обязательное поле!" }]}
        >
          <InputNumber style={{ width: "100%" }} min="0" max="100" />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          label="Дата начала"
          name="startDate"
          rules={[{ required: true, message: "Обязательное поле!" }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          label="Дата конца"
          name="expDate"
          rules={[{ required: true, message: "Обязательное поле!" }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          label="Статус"
          name="active"
          rules={[{ required: true, message: "Обязательное поле!" }]}
        >
          <Select
            style={{ width: "100%" }}
            options={[
              {
                value: true,
                label: "Активен",
              },
              {
                value: false,
                label: "Неактивен",
              },
            ]}
          />
        </Form.Item>

        <Space>
          <Button htmlType="submit" type="primary">
            Создать
          </Button>

          <Link to="/promocodes">
            <Button type="default">Назад</Button>
          </Link>
        </Space>
      </Form>
    </div>
  );
};
