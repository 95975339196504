import { apiRoutes } from "../routes";
import { ModelPromocode, QueryFields } from "../types/Models";
import { useDelete, useFetch, usePost } from "../utils/reactQuery";

export const useGetPromocodes = () => {
  const context = useFetch<ModelPromocode[]>(
    QueryFields.Promocodes,
    apiRoutes.getPromocodes,
    undefined,
    {
      retry: false,
    },
  );
  return { ...context, data: context.data };
};

export const useCreatePromocode = () => {
  return usePost(QueryFields.Promocodes, apiRoutes.createPromocode);
};

export const useDeletePromocode = () => {
  return useDelete(QueryFields.Promocodes, apiRoutes.deletePromocode);
};
