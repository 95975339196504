import { Button, Popconfirm, Row, Table, Typography } from "antd";
import {
  useDeletePromocode,
  useGetPromocodes,
} from "../../../hooks/usePromocodes";
import type { ColumnsType } from "antd/es/table";
import { ModelPromocode } from "../../../types/Models";
import { Link } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import { formatDate } from "../../../utils/utils";
import { Loader } from "../../shared/Loader/Loader";

const { Title } = Typography;

export const Promocodes = () => {
  const { data, isLoading } = useGetPromocodes();
  const mutationDelete = useDeletePromocode();

  if (isLoading) {
    return <Loader />;
  }

  const handleDelete = (name: string) => {
    mutationDelete.mutate(name);
  };

  const columns: ColumnsType<ModelPromocode> = [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Статус",
      dataIndex: "active",
      key: "active",
      defaultSortOrder: "ascend",
      sorter: (a, b) => Number(b.active) - Number(a.active),
      render: (active) => <span>{active ? "Активен" : "Неактивен"}</span>,
    },
    {
      title: "Скидка",
      dataIndex: "discount",
      key: "discount",
      render: (discount) => <span>{discount} %</span>,
    },
    {
      title: "Дата начала",
      dataIndex: "create_date",
      key: "create_date",
      render: (date) => <span>{formatDate(date)}</span>,
    },
    {
      title: "Истекает",
      dataIndex: "expiration_date",
      key: "expiration_date",
      render: (date) => <span>{formatDate(date)}</span>,
    },
    {
      title: "Действия",
      key: "action",
      render: (_, record) => (
        <Popconfirm
          title="Вы уверены, что хотите удалить этот промокод?"
          onConfirm={() => handleDelete(record.name)}
          okText="Да"
          cancelText="Нет"
        >
          <Button
            shape="circle"
            type="primary"
            danger
            icon={<DeleteOutlined />}
          />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <Row justify="space-between">
        <Title level={2}>Промокоды</Title>
        <Link to="/promocodes/create">
          <Button type="primary">Создать</Button>
        </Link>
      </Row>

      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};
