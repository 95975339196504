import { Badge, Descriptions } from "antd";
import { FC } from "react";
import { ModelUser } from "../../../types/Models";

interface UserDescriptionProps {
  user: Partial<ModelUser>;
}

export const UserDescription: FC<UserDescriptionProps> = ({ user }) => {
  return (
    <Descriptions>
      {user.email && (
        <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
      )}
      <Descriptions.Item label="Телефон">{user.phone}</Descriptions.Item>
      {user.bonusCount && (
        <Descriptions.Item label="Бонусы">
          <Badge
            className="site-badge-count-109"
            count={user.bonusCount}
            style={{ backgroundColor: "#52c41a" }}
            overflowCount={99999}
          />
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};
