import { apiRoutes } from "../routes";
import { ModelOrder, QueryFields } from "../types/Models";
import {
  useFetch,
  usePost,
  usePutOrder,
  usePutOrderSelectedTime,
} from "../utils/reactQuery";

export const useGetOrders = () => {
  const context = useFetch<ModelOrder[]>(
    QueryFields.Orders,
    apiRoutes.getOrders,
    undefined,
    {
      retry: false,
    },
  );
  return { ...context, data: context.data };
};

export const useGetOrdersByCity = (cityId: number) => {
  const context = useFetch<ModelOrder[]>(
    QueryFields.Orders,
    apiRoutes.getOrdersByCity,
    {
      city_id: cityId,
    },
    {
      retry: false,
    },
  );
  return { ...context, data: context.data };
};

export const useGetOrderById = (id: string | undefined) => {
  const context = useFetch<ModelOrder>(
    QueryFields.Orders,
    apiRoutes.getOrderById,
    {
      order_id: id,
    },
    {
      retry: false,
    },
  );
  return { ...context, data: context.data };
};

export const useEditOrder = (cityId: number) => {
  return usePutOrder(QueryFields.Orders, apiRoutes.editOrder, {
    city_id: cityId,
  });
};

export const useEditOrderSelectedTime = (cityId: number) => {
  return usePutOrderSelectedTime(QueryFields.Orders, apiRoutes.editOrder, {
    city_id: cityId,
  });
};

export const usePaymentResult = (cityId?: number) => {
  const params = cityId
    ? {
        city_id: cityId,
      }
    : {};

  return usePost(QueryFields.Orders, apiRoutes.markOrderPaid, params);
};
