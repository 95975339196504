import { Button, Form, InputNumber, Typography } from "antd";
import { useState } from "react";
import { useGetRates, useUpdateRate } from "../../../hooks/useRates";
import { Loader } from "../../shared/Loader/Loader";

const { Title } = Typography;

interface RatesFormData {
  RUB: number;
  USD: number;
  EUR: number;
}

export const Rates = () => {
  const [disabled, setDisabled] = useState(true);
  const { data, isLoading } = useGetRates();

  const mutationUpdate = useUpdateRate();

  const getRate = (rateName: string) => {
    if (!data) return null;
    for (let rate of data) {
      if (rate.name === rateName) {
        return rate;
      }
    }
  };

  const onFinish = (formData: RatesFormData) => {
    for (let [rate, value] of Object.entries(formData)) {
      const prev = getRate(rate);
      if (prev && prev.rate !== value) {
        mutationUpdate.mutate({ id: prev.id, value });
      }
    }
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <Title level={2}>Курсы валют</Title>

      <Form
        onFinish={onFinish}
        onFieldsChange={() => setDisabled(false)}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        initialValues={{
          RUB: getRate("RUB")?.rate,
          USD: getRate("USD")?.rate,
          EUR: getRate("EUR")?.rate,
        }}
      >
        <Form.Item name="RUB">
          <InputNumber prefix="₽" />
        </Form.Item>
        <Form.Item name="USD">
          <InputNumber prefix="$" />
        </Form.Item>
        <Form.Item name="EUR">
          <InputNumber prefix="€" />
        </Form.Item>

        <Button htmlType="submit" type="primary" disabled={disabled}>
          Обновить
        </Button>
      </Form>
    </>
  );
};
