import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  List,
  message,
  Modal,
  Popconfirm,
  Row,
  Typography,
} from "antd";
import { useState } from "react";
import {
  useCreateGroup,
  useDeleteGroup,
  useEditGroup,
  useGetGroups,
} from "../../../hooks/useGroup";
import { ModelGroup } from "../../../types/Models";
import { Loader } from "../../shared/Loader/Loader";

const { Title } = Typography;

export const Groups = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditGroup, setIsEditGroup] = useState<false | number>(false);
  const [form] = Form.useForm();

  const mutationAdd = useCreateGroup();
  const mutationDelete = useDeleteGroup();
  const mutationEdit = useEditGroup();

  const { data, isLoading } = useGetGroups();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    setIsEditGroup(false);
  };

  const handleEdit = (group: ModelGroup) => {
    form.setFieldValue("description", group.description);
    setIsEditGroup(group.id);
    showModal();
  };

  const handleDelete = (id: number) => {
    mutationDelete.mutate(id);
  };

  const onFinish = async (data: Pick<ModelGroup, "description">) => {
    if (isEditGroup) {
      try {
        await mutationEdit.mutateAsync({
          id: isEditGroup,
          description: data.description,
        });
        message.success("Категория успешно обновлена");
      } catch {
        message.error("Не удалось обновить категорию");
      } finally {
        handleCancel();
      }
    } else {
      try {
        await mutationAdd.mutateAsync(data);
        message.success("Категория успешно создана");
      } catch {
        message.error("Не удалось создать категорию");
      } finally {
        handleCancel();
      }
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Row justify="space-between">
        <Title level={2}>Категории</Title>
        <Button type="primary" onClick={showModal}>
          Добавить
        </Button>
      </Row>
      <List
        size="large"
        bordered
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                shape="circle"
                onClick={() => handleEdit(item)}
                icon={<EditOutlined />}
              />,
              <Popconfirm
                title="Вы уверены, что хотите удалить эту категорию?"
                onConfirm={() => handleDelete(item.id)}
                okText="Да"
                cancelText="Нет"
              >
                <Button
                  shape="circle"
                  type="primary"
                  danger
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>,
            ]}
          >
            {item.description}
          </List.Item>
        )}
      />
      <Modal
        title={isEditGroup ? "Редактировать категорию" : "Создать категорию"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form onFinish={onFinish} autoComplete="off" form={form}>
          <Form.Item
            label="Название"
            name="description"
            rules={[{ required: true, message: "Обязательное поле!" }]}
          >
            <Input />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Сохранить
          </Button>
        </Form>
      </Modal>
    </>
  );
};
