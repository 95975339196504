import {
  Badge,
  Button,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import {
  ModelOrder,
  orderDeliveryCity,
  orderPaymentStatus,
  OrderStatus,
  orderStatusText,
  selectedTimeText,
} from "../../../types/Models";
import type { ColumnsType } from "antd/es/table";
import {
  useEditOrder,
  useEditOrderSelectedTime,
  useGetOrdersByCity,
  usePaymentResult,
} from "../../../hooks/useOrders";
import { Link, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  CheckOutlined,
  EditOutlined,
  EyeOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { formatDate } from "../../../utils/utils";
import { Loader } from "../../shared/Loader/Loader";
import "./Orders.scss";

const { Title } = Typography;
const { Search } = Input;

export const Orders = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const cityId = Number(searchParams.get("city"));
  const [currentCityId, setCurrentCityId] = useState<number>(
    cityId === 0 ? 1 : cityId,
  );
  const [editOrder, setEditOrder] = useState<ModelOrder | null>(null);

  const { data, isLoading } = useGetOrdersByCity(currentCityId);
  const mutationEdit = useEditOrder(currentCityId);
  const mutationEditSelectedTime = useEditOrderSelectedTime(currentCityId);
  const mutationPaymentResult = usePaymentResult(currentCityId);

  const [displayData, setDisplayData] = useState<ModelOrder[] | undefined>(
    undefined,
  );

  useEffect(() => {
    setDisplayData(data);
  }, [data]);

  if (isLoading) {
    return <Loader />;
  }

  const toggleEditMode = (item: ModelOrder) => {
    if (item.order_id === editOrder?.order_id) {
      setEditOrder(null);
    } else {
      setEditOrder(item);
    }
  };

  const handleEditOrder = async (status: any) => {
    await mutationEdit.mutateAsync({
      id: editOrder?.order_id,
      status: OrderStatus[status],
    });
  };

  const handleEditSelectedTime = async (v: string) => {
    await mutationEditSelectedTime.mutateAsync({
      id: editOrder?.order_id,
      selectedTime: v,
    });
  };

  const handleSelectCity = (cityId: number) => {
    setCurrentCityId(cityId);
    const params = new URLSearchParams();
    params.set("city", String(cityId));
    setSearchParams(params);
  };

  const markPaid = (item: ModelOrder) => {
    mutationPaymentResult.mutate({
      order_id: item.order_id,
      payment_id: item.payment_id,
      status: "2",
    });
  };

  const cancelOrder = (item: ModelOrder) => {
    mutationPaymentResult.mutate({
      order_id: item.order_id,
      payment_id: item.payment_id,
      status: "4",
    });
  };

  const onSearch = (searchId: string) => {
    const filtered = data?.filter((item) => item.order_id.includes(searchId));
    setDisplayData(filtered);
  };

  const columns: ColumnsType<ModelOrder> = [
    {
      title: "Заказчик",
      dataIndex: "customer_f_name",
      key: "customer_f_name",
      render: (name, item) => (
        <div>
          <div>
            <Link to={item.order_id}>#{item.order_id}</Link>
          </div>
          <div>
            {name} {item.customer_s_name}
          </div>
          <div>
            <span>Тел: </span>
            {item.customer_phone}
          </div>
        </div>
      ),
    },
    {
      title: "Дата заказа",
      dataIndex: "create_date",
      key: "create_date",
      ellipsis: true,
      width: 180,
      render: (serverDate) => {
        const date = new Date(serverDate);
        date.setHours(date.getHours() + 6);
        return <span>{formatDate(date.toISOString())}</span>;
      },
    },
    {
      title: "Статус оплаты",
      dataIndex: "payment_status",
      key: "payment_status",
      render: (status, order) => {
        return (
          <Badge
            status={
              status === 2 ? "success" : status === 1 ? "processing" : "error"
            }
            text={orderPaymentStatus[status]}
          />
        );
      },
    },
    {
      title: "Статус заказа",
      dataIndex: "order_status",
      key: "order_status",
      render: (status, order) => {
        if (editOrder?.order_id === order.order_id) {
          return (
            <>
              <Select
                value={OrderStatus[status]}
                onSelect={handleEditOrder}
                style={{ minWidth: 170 }}
              >
                {Array.from(orderStatusText.entries()).map(([key, label]) => (
                  <Select.Option key={key} value={key}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
              <Select
                value={order.selectedTime}
                onSelect={handleEditSelectedTime}
                style={{ minWidth: 170, marginTop: 8 }}
              >
                {selectedTimeText.map((text, index) => (
                  <Select.Option key={index} value={text}>
                    {text}
                  </Select.Option>
                ))}
              </Select>
            </>
          );
        }
        return <div>{orderStatusText.get(OrderStatus[status])}</div>;
      },
    },
    {
      title: "Действия",
      key: "action",
      width: 180,
      render: (_, order) => {
        return (
          <Space>
            <Link to={order.order_id}>
              <Button
                shape="circle"
                icon={<EyeOutlined />}
                title="Посмотреть"
              />
            </Link>
            <Button
              shape="circle"
              onClick={() => toggleEditMode(order)}
              icon={
                editOrder?.order_id === order.order_id ? (
                  <CheckOutlined />
                ) : (
                  <EditOutlined />
                )
              }
              title="Редактировать"
            />
            {order.payment_status === 1 && (
              <Popconfirm
                title="Отметить оплаченным?"
                onConfirm={() => markPaid(order)}
                okText="Да"
                cancelText="Нет"
              >
                <Button
                  shape="circle"
                  icon={<CheckOutlined />}
                  title="Отметить оплаченным"
                />
              </Popconfirm>
            )}
            {order.payment_status !== 4 && order.payment_status !== 0 && (
              <Popconfirm
                title="Отменить заказ?"
                onConfirm={() => cancelOrder(order)}
                okText="Да"
                cancelText="Нет"
              >
                <Button
                  shape="circle"
                  title="Отменить заказ"
                  icon={<StopOutlined />}
                />
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Row justify="space-between">
        <Title level={2}>Заказы</Title>
        <div className="search">
          <Search
            placeholder="Найти заказ ..."
            addonBefore="#"
            onSearch={onSearch}
            enterButton
            type="number"
          />
        </div>

        <div>
          <Select
            value={currentCityId}
            onSelect={handleSelectCity}
            style={{ minWidth: 170 }}
          >
            {orderDeliveryCity.map((city, index) =>
              city ? (
                <Select.Option key={index} value={index}>
                  {city}
                </Select.Option>
              ) : null,
            )}
          </Select>
        </div>
      </Row>

      <div>
        <Table
          rowKey={(record) => record.order_id}
          columns={columns}
          dataSource={displayData}
        />
      </div>
    </>
  );
};
