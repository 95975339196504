import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { api } from "../services/api";
import { ModelGroup, QueryFields } from "../types/Models";

type QueryKeyT = [string, object | undefined];

export const useFetch = <T>(
  queryField: QueryFields,
  url: string,
  params?: object,
  config?: UseQueryOptions<T, Error, T, QueryKeyT>,
) => {
  const context = useQuery<T, Error, T, QueryKeyT>(
    [queryField, params],
    () => fetcher({ queryKey: [url, params], meta: undefined }),

    {
      enabled: !!url,
      ...config,
    },
  );

  return context;
};

export const fetcher = <T>({
  queryKey,
  pageParam,
}: QueryFunctionContext<QueryKeyT>): Promise<T> => {
  const [url, params] = queryKey;

  return api
    .get<T>(url, { params: { ...params, pageParam } })
    .then((res) => res.data);
};

export const useGenericMutation = <T, S>(
  func: (data: T | S) => Promise<AxiosResponse<S>>,
  queryField: QueryFields,
  params?: object,
  updater?: ((oldData: T, newData: S) => T) | undefined,
) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<S>, AxiosError, T | S>(func, {
    onMutate: async (data) => {
      await queryClient.cancelQueries([queryField, params]);
      const previousData = queryClient.getQueryData([queryField, params]);
      // queryClient.setQueryData<T>([queryField, params], (oldData) => {
      //   console.log(oldData, data);
      //   return data as T;
      // return updater ? updater(oldData!, data as S) : (data as T);
      // });
      return previousData;
    },
    onError: (err, _, context) => {
      queryClient.setQueryData([queryField, params], context);
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryField, params]);
    },
  });
};

export const useDelete = <T>(
  queryField: QueryFields,
  url: string,
  params?: object,
  updater?: (oldData: T, id: string | number) => T,
) => {
  return useGenericMutation<T, string | number>(
    (id) => api.delete(`${url}/${id}`),
    queryField,
    params,
    updater,
  );
};

export const usePost = <T, S>(
  queryField: QueryFields,
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T,
) => {
  return useGenericMutation<T, S>(
    (data) => api.post<S>(url, data),
    queryField,
    params,
    updater,
  );
};

export const useUpdate = <T, S>(
  queryField: QueryFields,
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T,
) => {
  return useGenericMutation<T, S>(
    (data) => api.put<S>(url, data),
    queryField,
    params,
    updater,
  );
};

export const usePutGroup = (queryField: QueryFields, url: string) => {
  return useGenericMutation<ModelGroup, ModelGroup>(
    (data) =>
      api.put<ModelGroup>(`${url}/${data.id}/${data.description}`, data),
    queryField,
  );
};

export const usePutOrder = (
  queryField: QueryFields,
  url: string,
  params?: object,
) => {
  return useGenericMutation(
    (data: any) => {
      return api.put<ModelGroup>(`${url}/${data.id}/${data.status}`, data);
    },
    queryField,
    params,
  );
};

export const usePutOrderSelectedTime = (
  queryField: QueryFields,
  url: string,
  params?: object,
) => {
  return useGenericMutation(
    (data: any) => {
      return api.put<ModelGroup>(
        `${url}/time/${data.id}/${data.selectedTime}`,
        data,
      );
    },
    queryField,
    params,
  );
};
