import axios from "axios";

export const api = Object.freeze({
  get: <T>(url: string, params?: object) => {
    return axios.get<T>(url, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
      ...params,
    });
  },
  post: <T>(url: string, data: any) =>
    axios.post<T>(url, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }),
  put: <T>(url: string, data: any) =>
    axios.put<T>(url, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }),
  patch: <T>(url: string, data: any) =>
    axios.patch<T>(url, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }),
  delete: <T>(url: string) =>
    axios.delete<T>(url, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }),
});
