import CryptoJS from "crypto-js";
import { apiRoutes } from "../routes";
import { api } from "../services/api";
import { ModelAuth, QueryFields } from "../types/Models";
import { useGenericMutation } from "../utils/reactQuery";

export const useLogin = () => {
  return useGenericMutation<any, ModelAuth>(({ username, password }) => {
    const hashedPassword = CryptoJS.MD5(password).toString();
    return api.post(
      `${apiRoutes.login}?username=${username}&password=${hashedPassword}`,
      {},
    );
  }, QueryFields.Auth);
};
