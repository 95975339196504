export const formatDate = (dateString: string, noTime?: boolean) => {
  const date = new Date(dateString);
  const dateStr = date.toLocaleDateString("ru");
  const timeStr = date.toLocaleTimeString("ru", {
    hour: "2-digit",
    minute: "2-digit",
  });
  if (noTime) return `${dateStr}`;
  return `${dateStr} | ${timeStr}`;
};

export const formatNumber = (price: number) => {
  return Math.ceil(price).toLocaleString("ru");
};
