import { EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import { MaskedInput } from "antd-mask-input";
import { useState } from "react";
import { useGetUsers, useUpdateUser } from "../../../hooks/useUsers";
import { ModelUser } from "../../../types/Models";
import { Loader } from "../../shared/Loader/Loader";
import { UserDescription } from "./UserDescription";

const { Title } = Typography;

export interface UserUpdateForm {
  email?: string;
  phone?: string;
  bonusCount?: string;
}

export const Users = () => {
  const { data, isLoading } = useGetUsers();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [seletedUser, setSelectedUser] = useState<null | ModelUser>(null);

  const mutationUpdate = useUpdateUser();

  const handleCancel = () => {
    setSelectedUser(null);
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  if (isLoading) return <Loader />;

  const handleEdit = (user: ModelUser) => {
    setSelectedUser(user);
    showModal();
  };

  const onFinish = async (data: UserUpdateForm) => {
    if (
      data.email === seletedUser?.email &&
      data.phone === seletedUser?.phone &&
      data.bonusCount === seletedUser?.bonusCount
    ) {
      handleCancel();
      return;
    }
    const updatedUser = { ...seletedUser, ...data };

    try {
      await mutationUpdate.mutateAsync(updatedUser as ModelUser);
      message.success("Пользователь успешно обновлен");
    } catch {
      message.error("Не удалось обновить пользователя");
    } finally {
      handleCancel();
    }
  };

  return (
    <>
      <Row justify="space-between">
        <Title level={2}>Пользователи</Title>
      </Row>

      <List
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                shape="circle"
                onClick={() => handleEdit(item)}
                icon={<EditOutlined />}
              />,
            ]}
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                >
                  {item.first_name[0]}
                </Avatar>
              }
              title={`${item.first_name} ${item.second_name}`}
              description={<UserDescription user={item} />}
            />
          </List.Item>
        )}
      />

      {seletedUser && (
        <Modal
          title={"Редактировать пользователя " + seletedUser.first_name}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
              email: seletedUser.email,
              phone: seletedUser.phone,
              bonusCount: seletedUser.bonusCount,
            }}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ type: "email", message: "Введите корректный email" }]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item label="Телефон" name="phone">
              <MaskedInput mask={"+{7}(000)000-00-00"} />
            </Form.Item>
            <Form.Item label="Бонусы" name="bonusCount">
              <InputNumber />
            </Form.Item>

            <Button htmlType="submit" type="primary">
              Сохранить
            </Button>
          </Form>
        </Modal>
      )}
    </>
  );
};
