import { apiRoutes } from "../routes";
import { api } from "../services/api";
import { ModelRate, QueryFields } from "../types/Models";
import { useFetch, useGenericMutation } from "../utils/reactQuery";

interface UpdateData {
  id: number;
  value: number;
}

export const useGetRates = () => {
  const context = useFetch<ModelRate[]>(
    QueryFields.Rates,
    apiRoutes.getRates,
    undefined,
    {
      retry: false,
    },
  );
  return { ...context, data: context.data };
};

export const useUpdateRate = () => {
  return useGenericMutation<UpdateData, UpdateData>(
    (data: UpdateData) =>
      api.put(`${apiRoutes.updateRates}/${data.id}/${data.value}`, data),
    QueryFields.Rates,
  );
};
