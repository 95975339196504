import { Button, Col, Form, Input, message, Row } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useLogin } from "../../../hooks/useLogin";
import "./Login.scss";
interface LoginForm {
  username: string;
  password: string;
}

export const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const fromPage = location.state?.from?.pathname || "/";

  const loginMutation = useLogin();

  const onFinish = async (values: LoginForm) => {
    try {
      const { data } = await loginMutation.mutateAsync(values);
      auth?.signin(data, () => navigate(fromPage));
    } catch (e) {
      message.error("Ошибка! Неверный логин или пароль");
    }
  };

  return (
    <div>
      <Row justify="center" align="middle" className="login-wrapper">
        <Col xs={6}>
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Логин"
              name="username"
              rules={[{ required: true, message: "Обязательное поле!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Пароль"
              name="password"
              rules={[{ required: true, message: "Обязательное поле!" }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Войти
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
