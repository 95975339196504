import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  DollarCircleOutlined,
  UserOutlined,
  GiftOutlined,
  ShoppingCartOutlined,
  // DollarCircleOutlined,
  // FolderOutlined,
  // ShoppingCartOutlined,
} from "@ant-design/icons";
import { Button, Col, Layout, Menu, Row } from "antd";
import React, { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const { Header, Sider, Content } = Layout;

export const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const auth = useAuth();

  const handleLogout = () => {
    auth?.signout();
  };

  const routeMap = {
    product: "3",
    groups: "2",
    rates: "4",
    users: "5",
    promocodes: "6",
    "promocodes/create": "6",
    orders: "7",
  };
  const activeRoute = location.pathname.slice(1) as keyof typeof routeMap;

  return (
    <Layout className="wrapper">
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[routeMap[activeRoute] || "1"]}
          items={[
            {
              key: "1",
              icon: <FolderOpenOutlined />,
              label: <Link to="/">Dashboard</Link>,
            },
            {
              key: "2",
              icon: <FolderOpenOutlined />,
              label: <Link to="/groups">Категории</Link>,
            },
            {
              key: "3",
              icon: <FolderOutlined />,
              label: <Link to="/products">Товары</Link>,
            },
            {
              key: "4",
              icon: <DollarCircleOutlined />,
              label: <Link to="/rates">Валюта</Link>,
            },
            {
              key: "5",
              icon: <UserOutlined />,
              label: <Link to="/users">Пользователи</Link>,
            },
            {
              key: "6",
              icon: <GiftOutlined />,
              label: <Link to="/promocodes">Промокоды</Link>,
            },
            {
              key: "7",
              icon: <ShoppingCartOutlined />,
              label: <Link to="/orders">Заказы</Link>,
            },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Row align="middle" justify="space-between">
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: () => setCollapsed(!collapsed),
              },
            )}

            <Button
              type="primary"
              style={{ marginRight: "16px" }}
              onClick={handleLogout}
            >
              Выйти
            </Button>
          </Row>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          <Row justify="center">
            <Col xs={24} xxl={16}>
              <Outlet />
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};
