import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Image, Row, UploadProps, Checkbox } from "antd";
import { Upload, Form, Input, Select } from "antd";
import { RcFile, UploadFile } from "antd/lib/upload";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { useEffect, useState } from "react";
import { useGetGroups } from "../../../hooks/useGroup";
import {
  useDeleteImage,
  useGetProductStatuses,
} from "../../../hooks/useProduct";
import { ModelImage, ModelProduct } from "../../../types/Models";

import "./ProductForm.scss";

interface ProductFormProps {
  onFinish: (data: ProductFormData, images: ModelImage[]) => void;
  product: ModelProduct | false;
}

export interface ProductFormData {
  name: string;
  desc: string;
  price: string;
  groupId: number;
  statusId: number;
  bonus: number;
}

const { Option } = Select;

export const ProductForm = ({ onFinish, product }: ProductFormProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [images, setImages] = useState<ModelImage[]>([]);
  const [hasBonus, setHasBonus] = useState<boolean>(false);

  const [form] = Form.useForm();
  const { data, isLoading } = useGetGroups();
  const { data: statuses, isLoading: isLoadingStatuses } =
    useGetProductStatuses();

  const mutationDelete = useDeleteImage();

  useEffect(() => {
    if (product) {
      form.setFieldsValue({
        name: product.product_name,
        desc: product.product_description,
        price: product.product_price[0].Value,
        groupId: product.group_id,
        statusId: product.status,
        bonus: product.bonus,
      });
      setImages(product.images || []);
      setHasBonus(Boolean(product ? product.bonus : false));
    } else {
      form.resetFields();
      setHasBonus(false);
    }

    return () => {
      setImages([]);
    };
  }, [product, form]);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setImages((imgs) => imgs.filter((img) => img.uid !== file.uid));
    },
    beforeUpload: async (file) => {
      const newImgBody = await getBase64(file);
      setFileList([...fileList, file]);
      setImages([...images, { id: file.uid, body: newImgBody, uid: file.uid }]);

      return false;
    },
    fileList,
  };

  const handleFinish = (data: ProductFormData) => {
    console.log("data", data);
    onFinish(data, images);
    form.resetFields();
    setImages([]);
    setFileList([]);
  };

  const handleDeleteImage = (id: number | string) => {
    if (typeof id === "number" && product) {
      mutationDelete.mutate({
        id,
        productId: product.id,
        groupId: product.group_id,
      });
    } else {
      setFileList((files) => files.filter((file) => file.uid !== id));
    }
    setImages((imgs) => imgs.filter((img) => img.id !== id));
  };

  const onChange = (e: CheckboxChangeEvent) => setHasBonus(e.target.checked);
  return (
    <Form onFinish={handleFinish} autoComplete="off" form={form}>
      <Form.Item
        label="Название"
        name="name"
        rules={[{ required: true, message: "Обязательное поле!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Описание" name="desc">
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item
        label="Категория"
        name="groupId"
        rules={[{ required: true, message: "Обязательное поле!" }]}
      >
        <Select loading={isLoading}>
          {data?.map((group) => (
            <Option key={group.id} value={group.id}>
              {group.description}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Alert
        style={{ marginBottom: 8 }}
        message="В зависимости от статуса, товар может быть отображен или скрыт на сайте"
        type="info"
      />
      <Form.Item
        label="Статус"
        name="statusId"
        rules={[{ required: true, message: "Обязательное поле!" }]}
      >
        <Select loading={isLoadingStatuses}>
          {statuses?.map((status) => (
            <Option key={status.id} value={status.id}>
              {status.description}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Цена"
        name="price"
        rules={[{ required: true, message: "Обязательное поле!" }]}
      >
        <Input type="number" />
      </Form.Item>

      <Checkbox
        checked={hasBonus}
        onChange={onChange}
        style={{ marginBottom: 16 }}
      >
        Начислять бонусы за покупку?
      </Checkbox>
      {hasBonus && (
        <Form.Item label="Введите сколько % начислять" name="bonus">
          <Input type="number" />
        </Form.Item>
      )}

      {images.length > 0 && (
        <Form.Item>
          <Row gutter={12}>
            {images.map((img) => (
              <Col key={img.id}>
                <div className="img-wrapper">
                  <Image width={100} src={img.body} preview={false} />
                  <Button
                    shape="circle"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteImage(img.id)}
                    className="img-delete"
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Form.Item>
      )}

      <Form.Item>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
      </Form.Item>

      <Button htmlType="submit" type="primary">
        Сохранить
      </Button>
    </Form>
  );
};
